import { createSlice } from "@reduxjs/toolkit";

let user={
  connected: false,
  id:null,
  data:{}
}

export const authenticationSlice = createSlice({
  name: "authentication",
  initialState: {
    connected: user.connected,
    id: user.id,
    data: user.data,
    lang: "FR",
    langs : ["FR", "EN", "ES"]
  },
  reducers: {
    setConnected: (state, action) => {
      state.connected = true;
    },
    setData: (state, action) => {
      state.data = action.payload;
      localStorage.setItem("user", JSON.stringify(action.payload))
    },
    setLang: (state, action) => {
      state.lang = action.payload;
    },
    setDisconnect: (state) => {
      state.connected = false
      state.token = null
      state.id = null
      state.data = {}
      localStorage.setItem("user", JSON.stringify({}))

    },
  },
});

export const { setConnected, setData, setDisconnect, setLang } = authenticationSlice.actions;

export default authenticationSlice.reducer;
