import React from "react";
import { useFrame } from '@react-three/fiber'
import { useGLTF, useTexture, Html } from '@react-three/drei'

import ArticleCard from "../molecules/ArticleCard"
import eventBus from "../../assets/scripts/utils/eventBus";
import * as THREE from "three"
import archeModel from "../../assets/3D/arche/Baked_Arches2.glb"
import archeBake from "../../assets/3D/arche/Baked_Arches2.jpg"



const lerp=(t,i,e)=>t*(1-e)+i*e

const ShopExperience = () => {

    const group1Ref = React.useRef()
    const [posZ, setPosZ] = React.useState(7.5)
    
    const [hovered, setHover] = React.useState(false)
    const [hoveredTime, setHoverTime] = React.useState(false)
    
    const [isTransitionPageLeft, setIsTransitionPageLeft] = React.useState(false)
    const [articles, setArticles] = React.useState([])
    const [categories, setCategories] = React.useState([])
    const [countInversions, setCountInversions] = React.useState(0)
    const [countArticles, setCountArticle] = React.useState([])
    const [fullArticleList, setFullArticleList] = React.useState([])
    const [g1Pos, setG1Pos] = React.useState(0)
    const [g2Pos, setG2Pos] = React.useState(0)
    const [areDisplayed, setAreDisplayed] = React.useState([])
    const [areVisible, setAreVisible] = React.useState([])
    const [space, setSpace] = React.useState(5)

    const [isMoving, setIsMoving] = React.useState(false)
    const [movingTo, setMovingTo] = React.useState(0)

    const [destinationCameraX,setDestinationCameraX] = React.useState(0)
    const [destinationCameraY,setDestinationCameraY] = React.useState(0)

    const [cameraX,setCameraX] = React.useState(0)
    const [cameraY,setCameraY] = React.useState(0)

    const initialPosition = 7.5

    let categoriesPosition = {}


    React.useEffect(() => {
        fetch("/content-index.json")
        .then(response => response.json())
        .then(json => {
            if(json.categories && json.articles){
                setCategories(json.categories)
                setArticles(() => {
                    const table = {}
                    let categ = {}

                    json.categories.map(c => {
                        table[c] = []
                        categ[c] = []
                    })

                    json.articles.map(a => {
                        table[a.refCategorie.name].push(a) 
                        categ[a.refCategorie.name].push("") 
                    })

                    json.categories.map(c => {
                        areVisible.push(
                            table[c].map(() => true)
                        )
                    })

                    const keys = Object.keys(table)
                    setFullArticleList(json.articles.sort((a,b) => {
                        a.refCategorie.name.localeCompare(b.refCategorie.name)
                    }))
                    

                    keys.map((key, index) => {
                        categ[key] = -(index * categ[key].length * 10 - 7.5)
                    })
                    categoriesPosition = categ

                    return table
                })
                setCountArticle(json.articles)
            }
        })
        .catch(() => {
            console.log("pas d'articles")
        })
        const startScene = () => {
            setTimeout(() => {
                setIsTransitionPageLeft(true) 
                // console.log("LOADED")   
            }, 1000)
        }

        eventBus.on("changeCategorie", (c) => {
            setMovingTo(c)
            setIsMoving(true)
        })


        setSpace(Math.max(Math.min((window.innerWidth - 768) / 672, 1), 0.01) * 5)
        
        window.addEventListener("resize", ()=> {
            setSpace(Math.max(Math.min((window.innerWidth - 768) / 672, 1), 0.01) * 5)
        })

        if(window.innerWidth > 768){

            window.addEventListener("mousemove", (e)=> {
                const x =  e.clientX / window.innerWidth - 0.5
                const y =  e.clientY / window.innerHeight - 0.5
                
                setDestinationCameraX(-x / 6)
                setDestinationCameraY(-y / 10)
                
            })
        }
        setTimeout(() => {
            startScene()
        }, 100)
        // eventBus.on("loaderLeave", startScene)
        // eventBus.on("routeChange", startScene) 
        return () => {
            // eventBus.remove("loaderLeave", startScene)
            // eventBus.remove("routeChange", startScene)
        }
    }, [])
    React.useEffect(() => {
        setHoverTime(0)
    }, [hovered])

    useFrame((state, delta) => {
        setHoverTime(ot => ot + delta)
        setIsTransitionPageLeft((e) => {
            if(e){
                if(!isMoving){
                    if(!hovered || hoveredTime < 1)
                        setPosZ( z => (z - window.lenis.velocity / 100 - 0.015))
                    else{
                        setPosZ( z => (z - window.lenis.velocity / 100 - (0.015/(1+hoveredTime*2))))
                    }
                    
                } else {
                    setPosZ(lerp(posZ, movingTo, 0.05))
                    if(Math.abs(posZ - movingTo) < 0.1){
                        setIsMoving(false)
                    }
                }
                
                setCameraX(lerp(cameraX, destinationCameraX, 0.05))
                setCameraY(lerp(cameraY, destinationCameraY, 0.05))
        
                state.camera.position.z = posZ
                state.camera.position.y = - 1
                state.camera.position.x = 0
              
                state.camera.rotation.y = cameraX
                state.camera.rotation.x = cameraY 
        
        
                if(posZ < - (countArticles.length-1) * 10 - 2.5){
                   setPosZ(22.5)
                }else if(posZ > 22.6 ){
                    setPosZ(- (countArticles.length-1) * 10 - 2.5)
                    
                }
        
        
                group1Ref.current.children.forEach((group, i) => {
                    
                    const distToCamera = (group1Ref.current.position.z + group.position.z) - state.camera.position.z

                    areVisible[i] = distToCamera > -20 && distToCamera < -7.5
                    if(distToCamera > -20 && distToCamera < -7.5){
                        areDisplayed[i] = distToCamera > -20 && distToCamera < -7.5

                    } else {
                        setTimeout(() => {
                            areDisplayed[i] = distToCamera > -20 && distToCamera < -7.5
                        }, 500)
                    }
             
                    if(distToCamera > -20 && distToCamera < 20){
                        eventBus.dispatch("imageLoaded", {id : `${i}`})
                    }


                    // const groupPos = group.position.z
                    /*group.children.forEach((child, i) => {
                        areVisible[j][i] = distToCamera > -20 && distToCamera < 20
                        if(distToCamera > -20 && distToCamera < 20){
                            eventBus.dispatch("imageLoaded", {id : `${i}`})
                        }
                    })*/
                    
                })
        
            }
        
            return e
        })

    })

    const model = useGLTF(archeModel)
    const texture = useTexture(archeBake)
    texture.flipY = false

    React.useEffect(() => {


        let currentPosition = Math.min(1, Math.max(0, -(posZ - initialPosition)/((countArticles.length-1) * 10 + Math.abs(initialPosition)))) // 30 === categories.length * 10

        eventBus.dispatch("updateProgress", {currentPosition: -posZ})

    }, [posZ])


    return <>


    {
    [...countArticles].map((a, i) => {
        return <mesh
        geometry={model.nodes["Arches"].geometry}
        position={[
            i%2 ? -0.323 * 4 : 0,
            -7,
            i%2 ? model.nodes["Arches"].position.z - (i * 56.1) + (4*5.4) : model.nodes["Arches"].position.z - (i * 56.1)
        ]}
        rotation-y={i % 2 ? Math.PI : 0}
        scale={4}
    >
        <meshBasicMaterial map={texture} side={THREE.DoubleSide} />
    </mesh>
    })
}




    <group ref={group1Ref} position={[0, 0, 0]}>
        {
            fullArticleList.map((article, index) => {
                return  <Html key={index}
            

                style={{
                    transition: 'opacity 0.5s',
                    opacity: areVisible[index] ? 1 : 0,
                    // display : areDisplayed[index] ? "none" : "block"
                }}
                wrapperClass={`G1-${article.refCategorie.name}-${index}`}
                distanceFactor={10} 
                position={[
                    index%2 !== 0 ? -space : space, 
                    0,
                    index * -10 // Proposer a 15
                ]}  
                center
            >
                <div
                    style={{
                    //     display : areDisplayed[index] ? "none" : "block"
                        zIndex : areVisible[index] ?  16186073 : '10000!important'
                    }}
                onMouseEnter={()=> {if(areVisible[index] ){setHover(true)}}}
                onMouseLeave={()=> {if(areVisible[index] ){setHover(false)}}}

                    > 

        {areDisplayed[index] && <ArticleCard imageID={`${index}`} product={article} lang={window.location.pathname.split("/").filter(w => w !== "")[0]?.toUpperCase()}/>}
                </div> 
    </Html>

})
}
</group>
    </>
}

export default ShopExperience