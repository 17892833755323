import React from "react"
import "./style.scss"
import Button from "../../atoms/Button"
import Picture from "../../atoms/Picture"
import {Link} from "gatsby"
import { useSelector, useDispatch } from "react-redux";
import { increaseQuantity, decreaseQuantity,removeProductFromCart, checkQuantity } from "../../../assets/scripts/store/redux-slices/cart";

const Index = (props) => {

    const { product, depth, isVisible3D, imageID, lang, type="3D"} = props


    let quantity = 0

    if(type === "3D" && product){

        return <article className="EMI-article" data-depth={depth}>
            <Link to={product.slug.current}>
            <Picture
                imageID={imageID}
                ctx="3D"
                url={product.images[0].img.asset.url}
                size={
                    {               
                        mobile: {
                            width: 352,
                        },
                        desktop: {
                            width: 493,
                        }
                    }
                }
            />
            <div>
                <p className="EMI-under-title-default">
                    {product[`name${lang === "FR" ? "" : lang}`]}
                </p>

                <p className="EMI-paragraph-default">
                    {parseFloat(product.price).toFixed(2)} €
                </p>
            </div>
            </Link>
        </article>

    } else {
        
        
    

        return <ArticleCart product={product} lang={lang} type={type}/>




    }



}


export default Index



const ArticleCart = ( props ) => {
    const { product, lang, type="3D"} = props


    const cart = useSelector((state) => state.cart) 
    const [colourInLang,setColourInLang] = React.useState("")

    const texts = {
        delete : {
            FR : "Supprimer",
            ES : "Borrar",
            EN : "Remove"
        },
        quantity : {
            FR : "Quantité",
            EN : "Quantity",
            ES : "Cantidad"
        }
    }

    React.useEffect(() => {

        product.product.qty.forEach(q => {
            if(q.colour === product.attribute.colour){
                setColourInLang(q[`colour${lang === "FR" ? "" : lang}`])
            }
        })
    
    }, [])


    const dispatch = useDispatch()
    const removeFromCart = () => {
        dispatch(removeProductFromCart(product))
        dispatch(checkQuantity())
    }
    const upadateQuantity = (state) => {
        if(state === "more"){
            dispatch(increaseQuantity(product))
            dispatch(checkQuantity())
        } else{
            dispatch(decreaseQuantity(product))
            dispatch(checkQuantity())
        }

    }


    return <li className="EMI-article-cart">
    <div className="EMI-article-cart-section">
        <Picture
            lazy={false}
            url={product.product.images[0].img.asset.url}
            size={
                {               
                    mobile: {
                        width: 169,
                    },
                    desktop: {
                        width: 274,
                    }
                }
            }
        />
    </div>
    <div className="EMI-article-cart-section">
        <div>
            <p className="EMI-under-title-default">
                {product.product[`name${lang === "FR" ? "": lang}`]}
            </p>
            <p className="EMI-paragraph-default">
                {parseFloat(product.product.price * product.attribute.quantity).toFixed(2)} €
            </p>
        </div>
        <div>
            {product.attribute.size && <span className="EMI-BUTTON_size_button selected EMI-paragraph-regular"> { product.attribute.size } </span>}
            {product.attribute.colour && <span className="EMI-BUTTON_size_button selected EMI-paragraph-regular"> {colourInLang } </span>}
        </div>
    </div>
    <div className="EMI-article-cart-section">
        {type==="small" ? <p className="EMI-under-title-light"> {texts.quantity[lang]} : {product.attribute.quantity}</p> : <Button callBack={(e) => upadateQuantity(e)} type="quantity" max={product.attribute.maxQty} value={product.attribute.quantity}></Button>}
        {type!=="small" && <Button type="text" callBack={() => removeFromCart()}>{texts.delete[lang]}</Button>}
    </div>
</li>
}