import React from "react"
import { Canvas, useFrame } from "@react-three/fiber";
import "./style.scss"
import ShopExperience from "../../experiences/ShopExperience.jsx"
import ArchitectureExperience from "../../experiences/ArchitectureExperience.jsx"
import Test from "../../experiences/Test.jsx"
import Santa from "../../experiences/Santa.jsx"
import eventBus from "../../../assets/scripts/utils/eventBus";
const ThreeLayout = (props) => {
    const [path, setPath] = React.useState("")

    
    React.useEffect(() => {

        setPath(window.location.pathname)

        eventBus.on("routeChange", () => {
            setTimeout(() => {
                setPath(window.location.pathname)
            }, 1000) 
        })
        console.log("path", window.location.pathname.split("/").includes("products"))
    }, [])


    return <div className="EMI-three_layout">
        <Canvas 
            flat
        >
                {(path === "/fr/products/" 
                    || path === "/en/products/" 
                    || path === "/es/products/"
                ) && <ShopExperience/>}
                {
                (   
                    path === "/" 
                    // path === "/payement/"||  
                    // path === "/my-account/" ||
                    // path === "/login/" ||
                    // path === "/signin/" ||
                    // path === "/ask-reset-password/" ||
                    // path === "/reset-password/" ||
                    // path === "/payement/success/"  || 
                    // path === "/my-orders/" 
                    ) && <ArchitectureExperience path={path}/>}
        </Canvas>
    </div>

}

export default ThreeLayout