import React from "react"

import "./style.scss"
import { useSelector, useDispatch } from "react-redux";
import { setCart } from "../../../assets/scripts/store/redux-slices/modals";
import Button from "../../atoms/Button";
import Article from "../../molecules/ArticleCard";


const Cart = (props) => {
    const dispatch = useDispatch();
    const isCartOpen = useSelector((state) => state.modals.cart);
    const cart = useSelector((state) => state.cart) // Pour afficher la quantitée d'articles    ≤
    const lang = useSelector((state) => state.authentication.lang) // Pour afficher la quantitée d'articles    ≤
    const auth = useSelector((state) => state.authentication) // Pour afficher la quantitée d'articles    ≤
    console.log(cart.cart.length)
    const closeCart = () => (
        dispatch(setCart(false))
    )

    React.useEffect(() => {
        if (isCartOpen) {
            window.lenis.stop()
            setTimeout(() => {


                document.querySelector('.EMI-CART').style.transform = "translate(0)"

                setTimeout(() => {
                    document.querySelector('.EMI-CART_head').style.transform = "translate(0)"
                    document.querySelector('.EMI-CART_head').style.opacity = "1"
                }, 480)

                setTimeout(() => {

                    cart.cart.forEach((items, index) => {
                        setTimeout(() => {
                            document.querySelectorAll('.EMI-CART li')[index].style.transform = "translate(0)"
                            document.querySelectorAll('.EMI-CART li')[index].style.opacity = "1"


                        }, index * 120)
                    });

                }, 720)

                if(cart.cart.length < 1 ){
                    setTimeout(() => {
                        document.querySelector(".EMI-CART_empty").style.transform = 'scaleY(1)'
                        setTimeout(() => {
                            document.querySelector(".EMI-CART_empty div").style.opacity = '1'
                            document.querySelector(".EMI-CART_empty div").style.transform = 'translate(0)'
                            
                        }, 900)
                    }, 240)
                }

                setTimeout(() => {
                    document.querySelector('.EMI-CART_foot').style.transform = "translate(-50%, 0)"
                    document.querySelector('.EMI-CART_foot').style.opacity = "1"
                }, cart.cart.length < 1 ? 1200 : 960)
            }, 50)
        } else {
/*
            document.querySelector('.EMI-CART_foot').style.transform = "translate(-50%, 100px)"
            document.querySelector('.EMI-CART_foot').style.opacity = "0"

            setTimeout(() => {
                cart.cart.forEach((items, index) => {
                    setTimeout(() => {
                        document.querySelectorAll('.EMI-CART li')[cart.cart.length - index - 1].style.transform = "translateY(100px)"
                        document.querySelectorAll('.EMI-CART li')[cart.cart.length - index - 1].style.opacity = "0"


                    }, index * 120)
                });

            }, 120)

            if(cart.cart.length < 1 ){
                setTimeout(() => {

                    document.querySelector(".EMI-CART_empty").style.transform = 'scaleY(0)'
                }, 120)
            }
            setTimeout(() => {
                document.querySelector('.EMI-CART_head').style.transform = "translateY(100px)"
                document.querySelector('.EMI-CART_head').style.opacity = "0"


            }, cart.cart.length * 120 + 200)

            setTimeout(() => {
                document.querySelector('.EMI-CART').style.transform = "translateY(100%)"
            }, cart.cart.length * 120 + 320)
*/
       

            window.lenis.start()    
            document.querySelector('.EMI-CART').style.transform = "translateY(100%)"


        }

    }, [isCartOpen])

    const texts = {
        emptyTitle: {
            FR: "Votre panier est vide",
            EN: "Your cart is empty",
            ES: "Tu carrito esta vacío"
        },
        emptyText: {
            FR: "¡Hey Amigo ! Pourquoi ne pas vous faire plaisir ?",
            EN: "¡Hey Amigo ! Why don't you enjoy ?",
            ES: "¡Hey Amigo ! ¿Por qué no darte un capricho?"
        },
        cart: {
            FR: "PANIER",
            EN: "CART",
            ES: "CESTA"
        },
        buy: {
            FR: "Commander",
            EN: "Buy",
            ES: "Orden"
        },
        subTotal: {
            FR: "SOUS-TOTAL PANIER",
            EN: "CART SUBTOTAL",
            ES: "SUBTOTAL DEL CARRITO"
        },
        back: {
            FR: "Retour",
            EN: "Back",
            ES: "Atrás"
        }
    }



    return <div className="EMI-CART">
        <div className="EMI-CART_head">
            <Button type="text" callBack={() => closeCart()}>{texts.back[lang]}</Button>
            <h1 className="EMI-medium-title"> {texts.cart[lang]} </h1>
        </div>

        {
            cart.cart.length < 1 ? <div className="EMI-CART_empty">
                <div >
                    <h2 className="EMI-medium-title">{texts.emptyTitle[lang]}</h2>
                    <p className="EMI-paragraph-default">{texts.emptyText[lang]}</p>
                </div>
            </div> : <ul data-lenis-prevent>
                {
                    cart.cart.map((article, index) => {
                        return <Article key={index} product={article} lang={lang} type="cart" />
                    })
                }
            </ul>
        }

        <div className="EMI-CART_foot">
            <p className="EMI-paragraph-default">{texts.subTotal[lang]}</p>
            <p className="EMI-under-title-bold">{parseFloat(cart.total).toFixed(2)} €</p>
            <Button
                to={
                    cart.cart.length > 0 ? auth.connected ? "/order" : "/login?redirect=order" : () => { }
                }
                customClass={cart.cart.length > 0 ? "clickable" : "not-clickable"}
            >{texts.buy[lang]}</Button>

        </div>
    </div>
}

export default Cart