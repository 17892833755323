import React from "react";
import "./style.scss"
import {isVisible} from "../../../assets/scripts/utils/dom"
import eventBus from "../../../assets/scripts/utils/eventBus"


const urlImageBuilder = (url, width, height, isWebp) => {
  const query = `${width ? 'w='+width+"&" : ""}${height ? 'h='+height+"&" : ""}${isWebp ? "fm=webp" : ""}`
  return `${url}?${query}`;
};





const Image = (props) => {
    const { images, alt, imageID, source, widthDesktop, heightDesktop, size, lazy=true, url=false, ctx} = props
    const [placeholderImageUrl,setPlaceholderImageUrl] = React.useState(url + "?w=10&h=10")
    const [isLoading, setIsLoading] = React.useState(true)

    const p = React.useRef()
    const placeholder = React.useRef()

    const imgs = url ? {
          mobile : {
            jpeg : [urlImageBuilder(url, size.mobile.width, size.mobile.height ), urlImageBuilder(url, size.mobile.width, size.mobile.height)],
            webp : [urlImageBuilder(url, size.mobile.width, size.mobile.height, true), urlImageBuilder(url, size.mobile.width, size.mobile.height, true)]
          },
          desktop : {
            jpeg : [urlImageBuilder(url, size.desktop.width, size.desktop.height ), urlImageBuilder(url, size.desktop.width, size.desktop.height)],
            webp : [urlImageBuilder(url, size.desktop.width, size.desktop.height , true), urlImageBuilder(url, size.desktop.width, size.desktop.height, true)]
          },
        } : images

    React.useEffect(() => {
      //Refaire le même système mais pour la 3D
     if(ctx!=="3D"){
      
        if( url && placeholder.current ){
          if(isVisible(placeholder.current)){

            placeholder.current.style.opacity = 0
            setIsLoading(false)
          }

          setTimeout(() => {
            window.lenis.on("scroll", () => {
                if( url && placeholder.current && isVisible(placeholder.current)){
                  if(placeholder.current){
                    placeholder.current.style.opacity = 0
                    setIsLoading(false)
                  }
                }
            })
            
          }, 500)
        }
      
      }else{
        eventBus.on("imageLoaded", (img) => {
          if(img.id === imageID){
            if(placeholder.current){
              placeholder.current.style.opacity = 0
              setIsLoading(false)
            }
          }
        })
      }
    }, [])


    return <picture className="EMI-PICTURE">

    {/* <source srcSet={imgs.desktop.webp[1]} type="image/webp" media="(min-width: 768px)"/>  */}

    <source srcSet={imgs.desktop.webp[0]} type="image/webp" media="(min-width: 768px)"/>
    <source srcSet={imgs.desktop.jpeg[0]}  media="(min-width: 768px)"/>
    {/* <source srcSet={imgs.mobile.jpeg[1]} media="(max-width: 767px)"/> */}


    {/* <source srcSet={imgs.desktop.jpeg[1]}  media="(min-width: 768px)"/> */}

    <source srcSet={imgs.mobile.webp[0]} type="image/webp" media="(max-width: 767px)"/>
    <source srcSet={imgs.mobile.jpeg[0]} media="(max-width: 767px)"/>
    {/* <source srcSet={imgs.mobile.webp[1]} type="image/webp" media="(max-width: 767px)"/> */}





    {(!lazy || !isLoading) && <img ref={p} src={imgs.desktop.jpeg[0]} data-src={imgs.desktop.jpeg[0]}  alt={alt} />}

    {lazy && (
          <div className="picture-blur" ref={placeholder}>
            <img
              src={placeholderImageUrl}
              alt={alt}
              width={widthDesktop}
              height={heightDesktop}
            />
          </div>
        )}
  </picture>

}

export default Image