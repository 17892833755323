import { createSlice } from "@reduxjs/toolkit";
import eventBus from "../../utils/eventBus.js"

    let cart={
      cart: [],
      quantity: 0,
      total: 0,
    }

const getProduct = (products, id, colour, size) => {
  return products.find((p) => p.product.slug.current === id && p.attribute.colour === colour && p.attribute.size === size);
};

export const cartSlice = createSlice({
  name: "cart",
  initialState: {
    cart: cart.cart, 
    quantity: cart.quantity, 
    total: cart.total,
  },
  reducers: {
    // OK
    addProductToCart: (state, action) => {
      const products = state.cart;
      const fullProduct = action.payload;
      const {product, attribute} = fullProduct;

      const existingProduct = getProduct(state.cart, product.slug.current, attribute.colour, attribute.size);

      let newState = [];
      if (existingProduct) {
        newState = products.map((p) => {
          if (
              p.product.slug.current === product.slug.current && 
              p.attribute.colour === attribute.colour && 
              p.attribute.size === attribute.size) {
                p.product.qty.forEach(q => {
                  if(q.colour === attribute.colour){
                    q.sizes.forEach(size => {
                      if(size.normalized === attribute.size || size.inches === attribute.size || size.centimeters === attribute.size){
                        if(p.attribute.quantity + attribute.quantity <=  Number(size.quantity)){
                          p.attribute.quantity += attribute.quantity;
                        } else {
                          p.attribute.quantity = Number(size.quantity);
                        }
                      }
                    });
                  } 
                });
          }
          return p;
        });
        state.cart = newState;
      } else {
        state.cart = [...products, fullProduct];
      }

      eventBus.dispatch("addProduct", {product})


    },
    //OK
    removeProductFromCart: (state, action) => {
      let products = state.cart;
      let {product, attribute} = action.payload;

      const newProducts = products.filter((p) => {
        return !(p.product.slug.current === product.slug.current && p.attribute.colour === attribute.colour &&  p.attribute.size === attribute.size)
      });

      state.cart = newProducts;
    },
    // Pas utilisé pour l'instant
    removeAllProductsFromCart: (state) => {
      state.cart = [];
    },
    // OK
    checkQuantity: (state, action) => {
      const products = state.cart;
      
      let quantity = 0;
      let total = 0
      
      products.forEach(p => {
      
        quantity += Number(p.attribute.quantity);
        total += Number(p.attribute.quantity) * Number(p.product.price);
      
      })
      
      state.quantity = quantity;
      state.total = total;

      console.log("on check Quantity")
      localStorage.setItem('santa_muerte_cart', JSON.stringify({
        quantity : state.quantity,
        total : state.total,
        cart : state.cart
      }))
    },
    // OK
    increaseQuantity: (state, action) => {
      const products = state.cart;
      const {product, attribute} = action.payload;

      const existingProduct = getProduct(state.cart, product.slug.current,  attribute.colour, attribute.size);
      let newState = [];
      if (existingProduct) {
        newState = products.map((p) => {
          if (
            p.product.slug.current === product.slug.current && 
            p.attribute.colour === attribute.colour && 
            p.attribute.size === attribute.size 
          ) {
            p.product.qty.forEach(q => {
              if(q.colour === attribute.colour){
                q.sizes.forEach(size => {
                  if(size.normalized === attribute.size || size.inches === attribute.size || size.centimeters === attribute.size){
                    if(attribute.quantity < size.quantity){
                      p.attribute.quantity += 1;
                    }
                  }
                });
              } 
            });
            
          }
          return p;
        });
        state.cart = newState;

      }
    },
    // OK
    decreaseQuantity: (state, action) => {
      const products = state.cart;
      const {product, attribute} = action.payload;

      const existingProduct = getProduct(state.cart, product.slug.current,  attribute.colour, attribute.size);
      let newState = [];
      if (existingProduct) {
        newState = products.map((p) => {
          if (
            p.product.slug.current === product.slug.current && 
            p.attribute.colour === attribute.colour && 
            p.attribute.size === attribute.size 
          ) {
            p.attribute.quantity = p.attribute.quantity > 1 ? p.attribute.quantity - 1 : 1;
          }
          return p;
        });
        state.cart = newState;
      }
    },


    setCart : (state, action) => {
      state.cart = action.payload.cart
      state.quantity = action.payload.quantity
      state.total = action.payload.total
    },
  },
});

export const {
  addProductToCart,
  removeProductFromCart,
  removeAllProductsFromCart,
  checkQuantity,
  increaseQuantity,
  decreaseQuantity,
  setCart,
} = cartSlice.actions;

export default cartSlice.reducer;
