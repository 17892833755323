import React from "react";
import reduxStore from '../../assets/scripts/store/redux'
import { Provider } from 'react-redux'
import "../../assets/styles/utils/root.scss";
import Header from "../organisms/Header"
import Footer from "../molecules/Footer"
import useSmoothScroll from "../../assets/scripts/hooks/useSmoothScroll"
import ThreeLayout from "./ThreeLayout";
import eventBus from "../../assets/scripts/utils/eventBus";
import HandlePersistantData from "./HandlePersistantData"
import Transition from "./Transition";
import Loader from "./Loader"
import padSource from "../../assets/audio/pad.mp3"
const Layout = (props) => {
    const { children } = props
    

    const scroll = useSmoothScroll("vertical");
    
    React.useEffect(() => {
        const padAudio = new Audio(padSource)
        padAudio.loop = true;
        padAudio.volume = 0.6;
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        if(window.location.pathname === "/fr/products/" || window.location.pathname === "/en/products/" || window.location.pathname === "/es/products/" || window.location.pathname === "/"){
            window.lenis.options.infinite = true
            if(window.location.pathname === "/"){
                window.lenis.options.touchMultiplier = 2.5
            } else {
                window.lenis.options.touchMultiplier = 1
            }

        } else {
            window.lenis.options.infinite = false
            window.lenis.options.touchMultiplier = 1
        }

        eventBus.on("audioStart", () => { padAudio.play() })
        eventBus.on("setVolume", (volume) => { interpolateAudio(padAudio, volume) })
        document.addEventListener("visibilitychange", () => {
            if (document.visibilityState === "visible") {
                padAudio.play();
            } else {
                padAudio.pause();
            }
          });
        eventBus.on('routeChange', (route) => {
            console.log(window.lenis)
            
            setTimeout(() => {
                if(route.pathname === "/fr/products/" || route.pathname === "/en/products/" || route.pathname === "/it/products/" || route.pathname === "/es/products/" || route.pathname === "/"){
                    window.lenis.options.infinite = true
                    if(route.pathname === "/"){
                        window.lenis.options.touchMultiplier = 2.5
                    } else {
                        window.lenis.options.touchMultiplier = 1
                    }
                } else {
                    window.lenis.options.infinite = false
                    window.lenis.options.touchMultiplier = 1
                }
            }, 1000)
        })
    }, [])
    const interpolateAudio = (padAudio, to) => { 
        let isSafari = ""
        const ua = navigator.userAgent.toLowerCase(); 
        if (ua.indexOf('safari') != -1) { 
          if (ua.indexOf('chrome') > -1) {
            if(to === 0 && padAudio.volume > 0){


                padAudio.volume = Math.max(0, padAudio.volume - 0.025)
                
                // padAudio.volume = Math.min(1, padAudio.volume + 0.05)
            setTimeout(() =>{
                interpolateAudio(padAudio, to)
            }, 20)
        }
        if(to === 1 && padAudio.volume < 0.6){
            // padAudio.volume = Math.max(0, padAudio.volume - 0.05)
                
            padAudio.volume = Math.min(0.6, padAudio.volume + 0.025)
        setTimeout(() =>{
            interpolateAudio(padAudio, to)
        }, 20)
        }
          } else {
            if(to === 0) {
                padAudio.pause()
            }else{
                padAudio.play()
            }
          }
        }
        console.log(padAudio.volume, to)
 
    }

    React.useEffect(() => {
        const jQuery = document.createElement("script");
        jQuery.src =
          "https://ajax.googleapis.com/ajax/libs/jquery/2.2.4/jquery.min.js";
        jQuery.async = "true";
    
        document.body.appendChild(jQuery);
    
        return () => {
          document.body.removeChild(jQuery);
        };
    });
    
    React.useEffect(() => {
        const scriptMr = document.createElement("script");
        scriptMr.src =
          "https://widget.mondialrelay.com/parcelshop-picker/jquery.plugin.mondialrelay.parcelshoppicker.min.js";
        scriptMr.async = "true";
    
        document.body.appendChild(scriptMr);
    
        return () => {
          document.body.removeChild(scriptMr);
        };
    });


    return <>
        <Provider store={reduxStore}>
            <Header />

            <Transition>
                <main>
                    {children}
                </main>
            </Transition>
    <Loader/>
            <HandlePersistantData/>
            <ThreeLayout />
            <Footer />
            <script defer type="text/javascript" src="https://api.pirsch.io/pirsch.js"
    id="pirschjs"
    data-code="R8roZanQPnI2w9vdgxZfs72AQybfwVbC"></script>
        </Provider>
    </>
}

export default Layout

export function Head () {
    return <>
{/* <script src="https:// jax.googleapis.com/ajax/libs/jquery/3.6.0/jquery.js" type="text/javascript" defer></script> */}
    </>
}