import React from "react";
import SantaIcon from "../../atoms/SantaIcon";
import "./style.scss"
import { SwitchTransition, Transition } from "react-transition-group";


const TransitionPage = (props) => {
    const {children } = props 
    const location = props.children.props.children.props.location.pathname

    
    return <>
    


    <SwitchTransition>
        <Transition
            key={location}
            unmountOnExit
            timeout={2300}
            onEnter={(node) => {
                console.log("ENTER")
                window.lenis.start()
                window.lenis.scrollTo(0, {immediate: true})




                document.querySelector(".EMI-TRANSITION").style.transition = `
                translate 1.5s cubic-bezier(.15,0,.15,1) 0.5s
                opacity 1.5s cubic-bezier(.15,0,.15,1) 0.7s
                `
                document.querySelector(".EMI-TRANSITION").style.transform = "translateY(-100%)"
                // document.querySelector(".EMI-TRANSITION").style.opacity = "0"


                document.querySelector(".EMI-TRANSITION .EMI-SANTAICON").style.transition = "0.5s cubic-bezier(.15,0,.15,1) 0s"
                document.querySelector(".EMI-TRANSITION .EMI-SANTAICON").style.opacity = "0"
                // document.querySelector(".EMI-TRANSITION .EMI-SANTAICON").style.transform = "translateY(-100px)"
                
                document.querySelector(".EMI-TRANSITION h1").style.transition = "0.5s cubic-bezier(.15,0,.15,1) 0s"
                document.querySelector(".EMI-TRANSITION h1").style.opacity = "0"
                // document.querySelector(".EMI-TRANSITION h1").style.transform = "translateY(-100px)"

                document.querySelector(".EMI-TRANSITION p").style.transition = "0.5s cubic-bezier(.15,0,.15,1) 0s"
                document.querySelector(".EMI-TRANSITION p").style.opacity = "0"
                // document.querySelector(".EMI-TRANSITION p").style.transform = "translateY(-100px)"

                document.querySelector(".EMI-TRANSITION .flower").style.transition = `
                opacity 0.5s cubic-bezier(.15,0,.15,1) 0s,
                transform 1s cubic-bezier(.15,0,.15,1) 0s`
                document.querySelector(".EMI-TRANSITION .flower").style.opacity = "0"
                document.querySelector(".EMI-TRANSITION .flower").style.transform = "translate(50%, calc(50% - 00px)) rotate(180deg)"


            }}
            onExit={(node) => {
                console.log("EXIT")
                window.lenis.stop()	
                //Replace 
  
                document.querySelector(".EMI-TRANSITION").style.transition = "0s"
                document.querySelector(".EMI-TRANSITION").style.transform = "translateY(100%)"
                document.querySelector(".EMI-TRANSITION").style.opacity = "0"

                
                document.querySelector(".EMI-TRANSITION .EMI-SANTAICON").style.transition = "0s"
                document.querySelector(".EMI-TRANSITION .EMI-SANTAICON").style.transform = "translateY(100px)"
                document.querySelector(".EMI-TRANSITION .EMI-SANTAICON").style.opacity = "0"

                document.querySelector(".EMI-TRANSITION h1").style.transition = "0s"
                document.querySelector(".EMI-TRANSITION h1").style.opacity = "0"
                document.querySelector(".EMI-TRANSITION h1").style.transform = "translateY(100px)"

                document.querySelector(".EMI-TRANSITION p").style.transition = "0s"
                document.querySelector(".EMI-TRANSITION p").style.opacity = "0"
                document.querySelector(".EMI-TRANSITION p").style.transform = "translateY(100px)"

                document.querySelector(".EMI-TRANSITION .flower").style.transition = "0s"
                document.querySelector(".EMI-TRANSITION .flower").style.opacity = "0"
                document.querySelector(".EMI-TRANSITION .flower").style.transform = "translate(50%, calc(50% + 100px))"


                setTimeout(() => {

                    document.querySelector(".EMI-TRANSITION").style.transition = "1s cubic-bezier(.85,0,.15,1) "
                    document.querySelector(".EMI-TRANSITION").style.transform = "translateY(0px)"
                    document.querySelector(".EMI-TRANSITION").style.opacity = "1"



                    document.querySelector(".EMI-TRANSITION .EMI-SANTAICON").style.transition = "1s cubic-bezier(.15,0,.15,1) 0.5s"
                    document.querySelector(".EMI-TRANSITION .EMI-SANTAICON").style.opacity = "1"
                    document.querySelector(".EMI-TRANSITION .EMI-SANTAICON").style.transform = "translateY(0)"


                    document.querySelector(".EMI-TRANSITION h1").style.transition = "1s cubic-bezier(.15,0,.15,1) 0.62s"
                    document.querySelector(".EMI-TRANSITION h1").style.opacity = "1"
                    document.querySelector(".EMI-TRANSITION h1").style.transform = "translateY(0)"


                    document.querySelector(".EMI-TRANSITION p").style.transition = "1s cubic-bezier(.15,0,.15,1) 0.74s"
                    document.querySelector(".EMI-TRANSITION p").style.opacity = "1"
                    document.querySelector(".EMI-TRANSITION p").style.transform = "translateY(0)"


                    document.querySelector(".EMI-TRANSITION .flower").style.transition = "1s cubic-bezier(.15,0,.15,1) 0.86s"
                    document.querySelector(".EMI-TRANSITION .flower").style.opacity = "1"
                    document.querySelector(".EMI-TRANSITION .flower").style.transform = "translate(50%, 50%) rotate(90deg)"



                }, 100)
                }}
        >
          {children}
        </Transition>
      </SwitchTransition>


    <div className="EMI-TRANSITION">

        <div className="EMI-TRANSITION_layout">
            <SantaIcon type="full" />
            <h1 className="EMI-footer">Santa Muerte</h1>
            <p className="EMI-under-title-light">santa-muerte.shop</p>



        <svg className="flower" width="56" height="57" viewBox="0 0 56 57" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26.3064 28.5715C26.3064 28.5715 6.68843 25.239 0.211914 41.588C0.211914 41.588 2.68002 43.1301 1.81564 45.2285C1.81564 45.2285 18.2244 44.1177 26.3064 28.5733V28.5715Z" fill="url(#paint0_linear_1509_11757)" />
            <path d="M26.5167 28.2746C26.5167 28.2746 19.8119 9.54092 2.38656 11.9021C2.38656 11.9021 2.25065 14.8087 0 15.0842C0 15.0842 9.00624 28.8454 26.5167 28.2764V28.2746Z" fill="url(#paint1_linear_1509_11757)" />
            <path d="M26.4842 28.2238C26.4842 28.2238 43.9712 18.7301 38.9716 1.87192C38.9716 1.87192 36.0776 2.18179 35.4633 0C35.4633 0 23.2424 11.0068 26.4842 28.2238Z" fill="url(#paint2_linear_1509_11757)" />
            <path d="M26.2866 28.2816C26.2866 28.2816 41.2692 41.376 55.3367 30.824C55.3367 30.824 54.0392 28.22 55.8713 26.8827C55.8713 26.8827 41.3018 19.2518 26.2866 28.2798V28.2816Z" fill="url(#paint3_linear_1509_11757)" />
            <path d="M26.2738 28.293C26.2738 28.293 20.1108 47.2133 35.3362 56.0093C35.3362 56.0093 37.2226 53.7931 39.1707 54.9547C39.1707 54.9547 40.4718 38.5586 26.2738 28.293Z" fill="url(#paint4_linear_1509_11757)" />
            <path d="M26.0058 28.9176C26.0058 28.9176 17.6954 20.9751 9.30529 26.705C9.30529 26.705 9.98665 28.2417 8.89575 28.9665C8.89575 28.9665 17.1083 33.7451 26.0058 28.9194V28.9176Z" fill="url(#paint5_linear_1509_11757)" />
            <path d="M26.2033 28.8432C26.2033 28.8432 29.0555 17.7078 19.9514 13.1992C19.9514 13.1992 18.9457 14.5456 17.7787 13.9494C17.7787 13.9494 17.6356 23.4504 26.2014 28.8432H26.2033Z" fill="url(#paint6_linear_1509_11757)" />
            <path d="M26.2034 28.8088C26.2034 28.8088 37.6451 29.9232 40.7058 20.2374C40.7058 20.2374 39.2198 19.4492 39.633 18.2061C39.633 18.2061 30.2227 19.5198 26.2034 28.8088Z" fill="url(#paint7_linear_1509_11757)" />
            <path d="M26.0891 28.7725C26.0891 28.7725 29.0229 39.888 39.169 39.3897C39.169 39.3897 39.3901 37.7225 40.7002 37.6772C40.7002 37.6772 36.1953 29.3107 26.0891 28.7743V28.7725Z" fill="url(#paint8_linear_1509_11757)" />
            <path d="M26.0801 28.7725C26.0801 28.7725 17.0086 35.8343 21.4483 44.971C21.4483 44.971 23.0683 44.5216 23.6247 45.7067C23.6247 45.7067 29.5521 38.2807 26.0783 28.7725H26.0801Z" fill="url(#paint9_linear_1509_11757)" />
            <path d="M26.6255 31.7533C28.1177 31.7533 29.3273 30.5437 29.3273 29.0515C29.3273 27.5593 28.1177 26.3496 26.6255 26.3496C25.1333 26.3496 23.9236 27.5593 23.9236 29.0515C23.9236 30.5437 25.1333 31.7533 26.6255 31.7533Z" fill="#F15A24" />
            <defs>
                <linearGradient id="paint0_linear_1509_11757" x1="0.211914" y1="36.7587" x2="26.3064" y2="36.7587" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F15A24" />
                    <stop offset="0.26" stopColor="#EA5325" />
                    <stop offset="0.64" stopColor="#D83F28" />
                    <stop offset="1" stopColor="#C1272D" />
                </linearGradient>
                <linearGradient id="paint1_linear_1509_11757" x1="6.5955" y1="9.53616" x2="19.3776" y2="32.2857" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F15A24" />
                    <stop offset="0.26" stopColor="#EA5325" />
                    <stop offset="0.64" stopColor="#D83F28" />
                    <stop offset="1" stopColor="#C1272D" />
                </linearGradient>
                <linearGradient id="paint2_linear_1509_11757" x1="41.9535" y1="5.67" x2="21.4272" y2="21.782" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F15A24" />
                    <stop offset="0.26" stopColor="#EA5325" />
                    <stop offset="0.64" stopColor="#D83F28" />
                    <stop offset="1" stopColor="#C1272D" />
                </linearGradient>
                <linearGradient id="paint3_linear_1509_11757" x1="52.8107" y1="34.9407" x2="30.5639" y2="21.3024" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F15A24" />
                    <stop offset="0.26" stopColor="#EA5325" />
                    <stop offset="0.64" stopColor="#D83F28" />
                    <stop offset="1" stopColor="#C1272D" />
                </linearGradient>
                <linearGradient id="paint4_linear_1509_11757" x1="30.5601" y1="55.3032" x2="34.3766" y2="29.4893" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F15A24" />
                    <stop offset="0.26" stopColor="#EA5325" />
                    <stop offset="0.64" stopColor="#D83F28" />
                    <stop offset="1" stopColor="#C1272D" />
                </linearGradient>
                <linearGradient id="paint5_linear_1509_11757" x1="10.8672" y1="24.3938" x2="23.3561" y2="32.8367" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F15A24" />
                    <stop offset="0.26" stopColor="#F05624" />
                    <stop offset="0.51" stopColor="#F04A24" />
                    <stop offset="0.75" stopColor="#EE3724" />
                    <stop offset="0.99" stopColor="#ED1D24" />
                    <stop offset="1" stopColor="#ED1C24" />
                </linearGradient>
                <linearGradient id="paint6_linear_1509_11757" x1="22.7312" y1="13.4296" x2="21.4881" y2="28.4533" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F15A24" />
                    <stop offset="0.26" stopColor="#F05624" />
                    <stop offset="0.51" stopColor="#F04A24" />
                    <stop offset="0.75" stopColor="#EE3724" />
                    <stop offset="0.99" stopColor="#ED1D24" />
                    <stop offset="1" stopColor="#ED1C24" />
                </linearGradient>
                <linearGradient id="paint7_linear_1509_11757" x1="40.9045" y1="23.0193" x2="25.8675" y2="24.0893" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F15A24" />
                    <stop offset="0.26" stopColor="#F05624" />
                    <stop offset="0.51" stopColor="#F04A24" />
                    <stop offset="0.75" stopColor="#EE3724" />
                    <stop offset="0.99" stopColor="#ED1D24" />
                    <stop offset="1" stopColor="#ED1C24" />
                </linearGradient>
                <linearGradient id="paint8_linear_1509_11757" x1="36.6313" y1="40.5421" x2="30.3965" y2="26.8167" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F15A24" />
                    <stop offset="0.26" stopColor="#F05624" />
                    <stop offset="0.51" stopColor="#F04A24" />
                    <stop offset="0.75" stopColor="#EE3724" />
                    <stop offset="0.99" stopColor="#ED1D24" />
                    <stop offset="1" stopColor="#ED1C24" />
                </linearGradient>
                <linearGradient id="paint9_linear_1509_11757" x1="19.3936" y1="43.0883" x2="29.5736" y2="31.9672" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F15A24" />
                    <stop offset="0.26" stopColor="#F05624" />
                    <stop offset="0.51" stopColor="#F04A24" />
                    <stop offset="0.75" stopColor="#EE3724" />
                    <stop offset="0.99" stopColor="#ED1D24" />
                    <stop offset="1" stopColor="#ED1C24" />
                </linearGradient>
            </defs>
        </svg>
        </div>

    </div>

    </>
}


export default TransitionPage