import React from "react"
import "./style.scss"
import Button from "../../atoms/Button"
import SantaIcon from "../../atoms/SantaIcon"
import UserNav from "../../molecules/UserNav"
import { useSelector, useDispatch } from 'react-redux';
import { setCart } from "../../../assets/scripts/store/redux-slices/modals";
import { setLang } from "../../../assets/scripts/store/redux-slices/authentication";
import Cart from "../cart";
import eventBus from "../../../assets/scripts/utils/eventBus"
import { navigate } from "gatsby"
import { getRectangle } from "../../../assets/scripts/utils/dom"
const Header = () => {
    const lang = useSelector((state) => state.authentication.lang);
    const langs = useSelector((state) => state.authentication.langs);
    const dispatch = useDispatch()
    const isCartOpen = useSelector((state) => state.modals.cart); 
    const quantity = useSelector((state) => state.cart.quantity); 


    const [isQuantityShown, setIsQuantityShown] = React.useState(false)
    const [isLangOpen, setIsLangOpen] = React.useState(false)
    const [isDeferLangOpen, setIsDeferLangOpen] = React.useState(false)
    const [isDeferCartOpen, setIsDeferCartOpen] = React.useState(false)
    const [volume, setVolume] = React.useState(1)
    const [volumeBlock, setVolumeBlock] = React.useState(false)

    const stopSound = () => {
        if(!volumeBlock){
            setVolumeBlock(true)
            
            eventBus.dispatch("setVolume", volume === 1 ? 0 : 1)
            setVolume(volume === 1 ? 0 : 1)

            setTimeout(() => {
                setVolumeBlock(false)
            }, 2000)
        }
    }

    const langRef = React.useRef()
    React.useEffect(() => {
        if(quantity > 0 ){
            setIsQuantityShown(true)
        } else {
            setTimeout(() => {
                setIsQuantityShown(false)
            }, 500)
        }
    }, [quantity])


    const txts = { 
        shopButton: {
            FR: "BOUTIQUE",
            EN: "SHOP",
            ES: "TIENDA",
            IT: "NEGOZIO",
            RU: "МАГАЗИН",
            DE: "",
            JA: "ショップ",
            CH: "商店"
        },
        home : {
            FR : "Accueil",
            EN : "Home",
        },
        cart : {
            FR : "Panier",
            EN : "Cart"
        }
    }
    

    const openCart = () => (
        dispatch(setCart(!isCartOpen))
    )


    React.useEffect(() => {
        eventBus.on('routeChange', () => {
            dispatch(setCart(false))
            setIsLangOpen(false)
            setIsDeferLangOpen(false)
        })
        eventBus.on("openCart", openCart)
        setTimeout(() => {
            window.lenis.on('scroll', () => {
                // console.log(document.querySelector(".EMI-FOOTER_foot"))
                const {y:footY} = getRectangle(document.querySelector(".EMI-FOOTER_foot"))
                const {y:rightY, height:rightHeight } = getRectangle(document.querySelector(".EMI-HEADER_bottom_rightIcons"))
                if(window.location.pathname !== "/" && window.location.pathname !== "/fr/products/" && window.location.pathname !== "/en/products/" && window.location.pathname !== "/es/products/" ){
                    
                    if(footY <= rightY + 100){

                        document.querySelector(".EMI-HEADER_bottom_rightIcons").style.opacity = 0
                        setTimeout(() => {
                            document.querySelector(".EMI-HEADER_bottom_rightIcons").style.display = "none"
                        }, 1000)
                    } else {
                        document.querySelector(".EMI-HEADER_bottom_rightIcons").style.display = "flex"
                        document.querySelector(".EMI-HEADER_bottom_rightIcons").style.opacity = 1
                    }
                }
            })
        }, 500)
    }, [])

    React.useEffect(() => {
        setIsLangOpen(false)
    }, [lang])

    React.useEffect(() => {
        if(isLangOpen){
            setIsDeferLangOpen(true);
            setTimeout(()=>{

                document.querySelector('.EMI-selectLang').style.transition = ""
                document.querySelector('.EMI-selectLang').style.transformOrigin = "bottom"
                document.querySelector('.EMI-selectLang').style.transform = "translateX(4px) scaleY(0)"
                document.querySelector('.EMI-selectLang').style.transition = "transform 1s cubic-bezier(.85,0,.15,1)"
                document.querySelector('.EMI-selectLang').style.transform = "translateX(4px) scaleY(1)"
                document.querySelectorAll('.EMI-selectLang button').forEach((button, i) => {
                    setTimeout(() => {
                        button.style.transition = "0s"
                        button.style.transform = "translateY(25px)"
                        
                        button.style.transition = "1s cubic-bezier(.25,0,.15,1)"
                        button.style.transform = "translate(0)"
                        button.style.opacity = "1"
                    }, i * 100 + 500)
                })
            },100)


        } else {
            if(document.querySelector('.EMI-selectLang')){

                document.querySelector('.EMI-selectLang').style.transformOrigin = "top"
                document.querySelector('.EMI-selectLang').style.transition = "transform 1s cubic-bezier(.85,0,.15,1) 0.5s"
                document.querySelector('.EMI-selectLang').style.transform = "translateX(4px) scaleY(0)"
                
                
                
                document.querySelectorAll('.EMI-selectLang button').forEach((button, i) => {
                    setTimeout(() => {
                        button.style.transition = "1s cubic-bezier(.25,0,.15,1)"
                        button.style.transform = "translateY(-25px)"
                        button.style.opacity = "0"
                        setTimeout(() => {
                            button.style.transition = "0s"
                            button.style.transform = "translateY(25px)"
                        }, 1000)
                    }, i * 100 )
                })
            }
                setTimeout(() => {
                    setIsDeferLangOpen(false)
                }, 1500)
            
        }
    }, [isLangOpen])

    React.useEffect(() => {
        if(isCartOpen){
            setIsDeferCartOpen(true)
        }else {
            setTimeout(() => {
                setIsDeferCartOpen(false)
            }, 1500)
        }
    }, [isCartOpen])

    React.useEffect(() => {
        console.log("dco", isDeferCartOpen)
    }, [isDeferCartOpen])

    
    return <header className="EMI-HEADER">
            <Button type="link" to={"/" + lang.toLowerCase() + "/products"} customClass="EMI-BUTTON-HEADER_main">{txts.shopButton[lang]}</Button>

            <Button type="icon" to="/" label={txts.home[lang]}> <SantaIcon /> </Button>

            <div className="EMI-HEADER_rightIcons">

                <UserNav/>


                <Button type="icon" callBack={() => openCart()} label={txts.cart[lang]}> 
                    <svg width="29" height="33" viewBox="0 0 29 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.5 8.00781H26.9025V28.0009C26.9025 29.9339 25.3355 31.5009 23.4025 31.5009H5C3.067 31.5009 1.5 29.9339 1.5 28.0009V8.00781Z" stroke="#635F5D" strokeWidth="3"/>
                        <path d="M19.7082 6.50694C19.7082 3.46554 17.2427 1 14.2013 1C11.1599 1 8.69434 3.46554 8.69434 6.50694" stroke="#635F5D" strokeWidth="2"/>
                    </svg>
                    <div className={"EMI-HEADER_cartIndicator" + ' ' + `${quantity > 0 ? "fill" : "empty"}` + " " +  `${quantity > 9 ? "big" : ""}`}><span>{quantity}</span></div> 
                </Button>


            </div>

            {
                isDeferCartOpen && <Cart /> 
            }
            
            <div className="EMI-HEADER_bottom_rightIcons">
                <Button type={volume === 1 ?"sound" : "sound-off"} callBack={() => {stopSound()}}/> 
                <Button type="lang" callBack={() => setIsLangOpen(!isLangOpen)}>{lang}</Button>
            </div>

            {
                isDeferLangOpen && <div className="EMI-selectLang">
                    {langs.filter((l) => l !== lang ).map((l, i) => <Button key={i} type="lang" callBack={() => {
                        dispatch(setLang(l))

                        const splitURL = window.location.pathname.split("/").filter(w => w !== "")

                        if(splitURL[0] && (splitURL[0] === "fr" || splitURL[0] === "en" || splitURL[0] === "es" || splitURL[0] === "it")){
                            splitURL[0] = l.toLowerCase()
                            navigate("/" + splitURL.join("/"))
                        }

                    }}>{l}</Button>)}
                </div>
            }   
    </header>
}

export default Header