import React from "react"
import "./style.scss"
import Button from "../../atoms/Button"
import { useSelector, useDispatch } from "react-redux";
import { setDisconnect } from "../../../assets/scripts/store/redux-slices/authentication";
import {useOutsideClick} from "../../../assets/scripts/hooks/useClickOutside";
import eventBus from "../../../assets/scripts/utils/eventBus.js"

const UserNav = () => {
    const auth = useSelector((state) => state.authentication) 
    const dispatch = useDispatch()
    const [isLoggedin, setIsConnected] = React.useState(auth.connected)
    const [isModalOpen, setIsModalOpen] = React.useState(false)
    const [isDeferModalOpen, setIsDeferModalOpen] = React.useState(false)
    const modale = React.useRef(null);
    const lang = useSelector((state) => state.authentication.lang);

    const disconnect = () => {
        dispatch(setDisconnect())
        setIsModalOpen(false)
    }
    const texts = {
        account: {
            EN : "My account",
            FR : "Mon compte",
            ES : "Mi cuenta",
        },
        loggout: {
            EN : "Loggout",
            FR : "Déconnexion",
            ES : "Desconectar"
        }, 
        login : {
            FR : "Se connecter",
            EN : "Log in",
            ES : "acceso"
        }
    }
    
    useOutsideClick(modale, () => {
        setIsModalOpen(false)
    })

    React.useEffect(() => {
        console.log("c: ", isLoggedin, auth.connected)
        eventBus.on('routeChange', () => {
            // setIsDeferModalOpen(false)
            setIsModalOpen(false)
        })
    }, [])

    React.useEffect(() => {
        if(isModalOpen){
            setIsDeferModalOpen(true)
            setTimeout(() => {
                document.querySelector(".EMI-USERNAV_modale").style.transform = `scaleY(1)`
                setTimeout(() => {
                    document.querySelector(".EMI-USERNAV_modale button").style.transform = `translateY(0)`
                    document.querySelector(".EMI-USERNAV_modale button").style.opacity = `1`
                }, 350);
                
                setTimeout(() => {
                    document.querySelector(".EMI-USERNAV_modale a").style.transform = `translateY(0)`
                    document.querySelector(".EMI-USERNAV_modale a").style.opacity = `1`
                }, 450);
                
            }, 100);
        } else {

            if(isDeferModalOpen){
                document.querySelector(".EMI-USERNAV_modale a").style.transform = `translateY(-25px)`
                document.querySelector(".EMI-USERNAV_modale a").style.opacity = `0`
                
                setTimeout(() => {
                    document.querySelector(".EMI-USERNAV_modale button").style.transform = `translateY(-25px)`
                    document.querySelector(".EMI-USERNAV_modale button").style.opacity = `0`
                }, 120);
                setTimeout(() => {
                    document.querySelector(".EMI-USERNAV_modale").style.transform = `scaleY(0)`
                }, 600)
                setTimeout(() => {
                    // setIsDeferModalOpen(false)
                }, 1600);
            }
            
        }
    }, [isModalOpen])
    
    React.useEffect(() => {

        setIsConnected(auth.connected)
    }, [auth.connected])

    return <>
    
        <div className="EMI-USERNAV">
            <Button type="icon" callBack={() => setIsModalOpen(!isModalOpen)} to={isLoggedin ? false : "/login"} label={isLoggedin ? `${texts.account[lang]} / ${texts.loggout[lang]}`: texts.login[lang]}> 
                <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="16.2493" cy="9.48927" r="7.98927" stroke="#635F5D" strokeWidth="3"/>
                    <path d="M16.2492 18.8144C28.5182 21.1375 29.0603 24.829 28.4774 31.9999" stroke="#635F5D" strokeWidth="3"/>
                    <path d="M16.2495 18.8144C3.98056 21.1375 3.43843 24.829 4.02142 31.9999" stroke="#635F5D" strokeWidth="3"/>
                </svg>
            </Button>
        </div>
        <div ref={modale}>
        {
            isDeferModalOpen && <div className="EMI-USERNAV_modale">
                <Button type="link" to="/my-account" customClass="EMI-button-big" label={texts.account[lang]}> 
                    {texts.account[lang]}
                </Button> <br/>
                <Button type="text" callBack={() => disconnect()} label={texts.loggout[lang]}> 
                    {texts.loggout[lang]}
                </Button>
            </div>
        }
        </div>

    
    </>
}

export default UserNav