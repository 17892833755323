exports.components = {
  "component---src-components-templates-article-index-js": () => import("./../../../src/components/templates/article/index.js" /* webpackChunkName: "component---src-components-templates-article-index-js" */),
  "component---src-components-templates-legals-index-js": () => import("./../../../src/components/templates/legals/index.js" /* webpackChunkName: "component---src-components-templates-legals-index-js" */),
  "component---src-components-templates-products-js": () => import("./../../../src/components/templates/products.js" /* webpackChunkName: "component---src-components-templates-products-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-activate-js": () => import("./../../../src/pages/activate.js" /* webpackChunkName: "component---src-pages-activate-js" */),
  "component---src-pages-ask-reset-password-js": () => import("./../../../src/pages/ask-reset-password.js" /* webpackChunkName: "component---src-pages-ask-reset-password-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-my-account-js": () => import("./../../../src/pages/my-account.js" /* webpackChunkName: "component---src-pages-my-account-js" */),
  "component---src-pages-my-orders-js": () => import("./../../../src/pages/my-orders.js" /* webpackChunkName: "component---src-pages-my-orders-js" */),
  "component---src-pages-order-index-js": () => import("./../../../src/pages/order/index.js" /* webpackChunkName: "component---src-pages-order-index-js" */),
  "component---src-pages-payement-cancel-js": () => import("./../../../src/pages/payement/cancel.js" /* webpackChunkName: "component---src-pages-payement-cancel-js" */),
  "component---src-pages-payement-success-js": () => import("./../../../src/pages/payement/success.js" /* webpackChunkName: "component---src-pages-payement-success-js" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-signin-js": () => import("./../../../src/pages/signin.js" /* webpackChunkName: "component---src-pages-signin-js" */)
}

