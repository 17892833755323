import React from "react";


import { navigate } from 'gatsby';

// Three 
import { Canvas, useFrame, useThree } from '@react-three/fiber'
import * as THREE from "three"
import { useGLTF, useTexture, useProgress } from '@react-three/drei'

//3D Models
import architecture from "../../assets/3D/scene/architecture/ArchitectureBis3.glb"
import architectureBaked from "../../assets/3D/scene/architecture/Architecture_mod.jpg"

import waterBake from "../../assets/3D/scene/architecture/Water2.jpg"

import accessories from "../../assets/3D/scene/accessoires/BakedAccessoires_Compressed.glb"
import accessoriesBake from "../../assets/3D/scene/accessoires/BakedAccessoires.jpg"

import mask from "../../assets/3D/scene/body/Masque_Compressed.glb"
import maskBake from "../../assets/3D/scene/body/Masquev2retouche.jpg"

import body from "../../assets/3D/scene/body/Dressbas.glb"
import bodyBake from "../../assets/3D/scene/body/Baked_Dress.jpg"

import voile from "../../assets/3D/scene/body/BakedVoile.glb"
import voileBake from "../../assets/3D/scene/body/Voile.jpg"

import bdy from "../../assets/3D/scene/body/Santa002.glb"
import bdyBake from "../../assets/3D/scene/body/Santa002bis.jpg"

import buste from "../../assets/3D/scene/body/BakedBusteBisv2.glb"
import busteBake from "../../assets/3D/scene/body/Bustebis2.jpg"



// Animations
import travelingState from "../../assets/3D/scene/travelingState.json"
import { getProject, val } from '@theatre/core'
import { SheetProvider, PerspectiveCamera, useCurrentSheet, } from "@theatre/r3f";

import studio from "@theatre/studio";
import extension from "@theatre/r3f/dist/extension";
import eventBus from "../../assets/scripts/utils/eventBus";




// studio.initialize()
// studio.extend(extension);


// const lang = useSelector((state) => state.authentication.lang);
const lerp = (t, i, e) => t * (1 - e) + i * e

const fragmentShader = `    
precision mediump float;
uniform sampler2D uTexture;

uniform float uColorOffset;
uniform float uColorMultiplier;

varying float vElevation;
varying vec2 vUv;
void main()
{
    float mixStrength = (vElevation + uColorOffset) * uColorMultiplier;
    vec4 textureColor = texture2D(uTexture, vUv);
    textureColor.rgb += 0.5 ;
    textureColor.rgb *= mixStrength * 2.0 ;
    gl_FragColor = textureColor;    
}
`

const vertexShader = `

uniform vec2 uBigWavesFrequency;
uniform float uBigWavesElevation;
uniform float uTime;
varying vec2 vUv;
varying float vElevation;



vec4 permute(vec4 x)
{
    return mod(((x*34.0)+1.0)*x, 289.0);
}
vec4 taylorInvSqrt(vec4 r)
{
    return 1.79284291400159 - 0.85373472095314 * r;
}
vec3 fade(vec3 t)
{
    return t*t*t*(t*(t*6.0-15.0)+10.0);
}

float cnoise(vec3 P)
{
    vec3 Pi0 = floor(P); // Integer part for indexing
    vec3 Pi1 = Pi0 + vec3(1.0); // Integer part + 1
    Pi0 = mod(Pi0, 289.0);
    Pi1 = mod(Pi1, 289.0);
    vec3 Pf0 = fract(P); // Fractional part for interpolation
    vec3 Pf1 = Pf0 - vec3(1.0); // Fractional part - 1.0
    vec4 ix = vec4(Pi0.x, Pi1.x, Pi0.x, Pi1.x);
    vec4 iy = vec4(Pi0.yy, Pi1.yy);
    vec4 iz0 = Pi0.zzzz;
    vec4 iz1 = Pi1.zzzz;

    vec4 ixy = permute(permute(ix) + iy);
    vec4 ixy0 = permute(ixy + iz0);
    vec4 ixy1 = permute(ixy + iz1);

    vec4 gx0 = ixy0 / 7.0;
    vec4 gy0 = fract(floor(gx0) / 7.0) - 0.5;
    gx0 = fract(gx0);
    vec4 gz0 = vec4(0.5) - abs(gx0) - abs(gy0);
    vec4 sz0 = step(gz0, vec4(0.0));
    gx0 -= sz0 * (step(0.0, gx0) - 0.5);
    gy0 -= sz0 * (step(0.0, gy0) - 0.5);

    vec4 gx1 = ixy1 / 7.0;
    vec4 gy1 = fract(floor(gx1) / 7.0) - 0.5;
    gx1 = fract(gx1);
    vec4 gz1 = vec4(0.5) - abs(gx1) - abs(gy1);
    vec4 sz1 = step(gz1, vec4(0.0));
    gx1 -= sz1 * (step(0.0, gx1) - 0.5);
    gy1 -= sz1 * (step(0.0, gy1) - 0.5);

    vec3 g000 = vec3(gx0.x,gy0.x,gz0.x);
    vec3 g100 = vec3(gx0.y,gy0.y,gz0.y);
    vec3 g010 = vec3(gx0.z,gy0.z,gz0.z);
    vec3 g110 = vec3(gx0.w,gy0.w,gz0.w);
    vec3 g001 = vec3(gx1.x,gy1.x,gz1.x);
    vec3 g101 = vec3(gx1.y,gy1.y,gz1.y);
    vec3 g011 = vec3(gx1.z,gy1.z,gz1.z);
    vec3 g111 = vec3(gx1.w,gy1.w,gz1.w);

    vec4 norm0 = taylorInvSqrt(vec4(dot(g000, g000), dot(g010, g010), dot(g100, g100), dot(g110, g110)));
    g000 *= norm0.x;
    g010 *= norm0.y;
    g100 *= norm0.z;
    g110 *= norm0.w;
    vec4 norm1 = taylorInvSqrt(vec4(dot(g001, g001), dot(g011, g011), dot(g101, g101), dot(g111, g111)));
    g001 *= norm1.x;
    g011 *= norm1.y;
    g101 *= norm1.z;
    g111 *= norm1.w;

    float n000 = dot(g000, Pf0);
    float n100 = dot(g100, vec3(Pf1.x, Pf0.yz));
    float n010 = dot(g010, vec3(Pf0.x, Pf1.y, Pf0.z));
    float n110 = dot(g110, vec3(Pf1.xy, Pf0.z));
    float n001 = dot(g001, vec3(Pf0.xy, Pf1.z));
    float n101 = dot(g101, vec3(Pf1.x, Pf0.y, Pf1.z));
    float n011 = dot(g011, vec3(Pf0.x, Pf1.yz));
    float n111 = dot(g111, Pf1);

    vec3 fade_xyz = fade(Pf0);
    vec4 n_z = mix(vec4(n000, n100, n010, n110), vec4(n001, n101, n011, n111), fade_xyz.z);
    vec2 n_yz = mix(n_z.xy, n_z.zw, fade_xyz.y);
    float n_xyz = mix(n_yz.x, n_yz.y, fade_xyz.x); 
    return 2.2 * n_xyz;
}




void main()
{

    vUv = uv;


    
    vec4 modelPosition = modelMatrix * vec4(position, 1.0);
    
    // float elevation = sin(modelPosition.x * uBigWavesFrequency.x + uTime) * sin(modelPosition.z * uBigWavesFrequency.y + uTime) * uBigWavesElevation;
    
    
    float elevation = abs(cnoise(vec3(modelPosition.xz * .5, uTime * 0.25)) * 0.15);
    
    modelPosition.y += elevation;





    vElevation = elevation;

    vec4 viewPosition = viewMatrix * modelPosition;
    vec4 projectedPosition = projectionMatrix * viewPosition;

    
    gl_Position = projectedPosition;
}


`

const coneVertexShader = `
varying vec2 vUv;

void main()
{
    vUv = uv;
    gl_Position = projectionMatrix * viewMatrix * modelMatrix * vec4(position, 1.0);
}
`
const coneFragmentShader = `
precision mediump float;
varying vec2 vUv;
uniform float uTime;


vec4 permute(vec4 x)
{
    return mod(((x*34.0)+1.0)*x, 289.0);
}
vec4 taylorInvSqrt(vec4 r)
{
    return 1.79284291400159 - 0.85373472095314 * r;
}
vec3 fade(vec3 t)
{
    return t*t*t*(t*(t*6.0-15.0)+10.0);
}

float cnoise(vec3 P)
{
    vec3 Pi0 = floor(P); // Integer part for indexing
    vec3 Pi1 = Pi0 + vec3(1.0); // Integer part + 1
    Pi0 = mod(Pi0, 289.0);
    Pi1 = mod(Pi1, 289.0);
    vec3 Pf0 = fract(P); // Fractional part for interpolation
    vec3 Pf1 = Pf0 - vec3(1.0); // Fractional part - 1.0
    vec4 ix = vec4(Pi0.x, Pi1.x, Pi0.x, Pi1.x);
    vec4 iy = vec4(Pi0.yy, Pi1.yy);
    vec4 iz0 = Pi0.zzzz;
    vec4 iz1 = Pi1.zzzz;

    vec4 ixy = permute(permute(ix) + iy);
    vec4 ixy0 = permute(ixy + iz0);
    vec4 ixy1 = permute(ixy + iz1);

    vec4 gx0 = ixy0 / 7.0;
    vec4 gy0 = fract(floor(gx0) / 7.0) - 0.5;
    gx0 = fract(gx0);
    vec4 gz0 = vec4(0.5) - abs(gx0) - abs(gy0);
    vec4 sz0 = step(gz0, vec4(0.0));
    gx0 -= sz0 * (step(0.0, gx0) - 0.5);
    gy0 -= sz0 * (step(0.0, gy0) - 0.5);

    vec4 gx1 = ixy1 / 7.0;
    vec4 gy1 = fract(floor(gx1) / 7.0) - 0.5;
    gx1 = fract(gx1);
    vec4 gz1 = vec4(0.5) - abs(gx1) - abs(gy1);
    vec4 sz1 = step(gz1, vec4(0.0));
    gx1 -= sz1 * (step(0.0, gx1) - 0.5);
    gy1 -= sz1 * (step(0.0, gy1) - 0.5);

    vec3 g000 = vec3(gx0.x,gy0.x,gz0.x);
    vec3 g100 = vec3(gx0.y,gy0.y,gz0.y);
    vec3 g010 = vec3(gx0.z,gy0.z,gz0.z);
    vec3 g110 = vec3(gx0.w,gy0.w,gz0.w);
    vec3 g001 = vec3(gx1.x,gy1.x,gz1.x);
    vec3 g101 = vec3(gx1.y,gy1.y,gz1.y);
    vec3 g011 = vec3(gx1.z,gy1.z,gz1.z);
    vec3 g111 = vec3(gx1.w,gy1.w,gz1.w);

    vec4 norm0 = taylorInvSqrt(vec4(dot(g000, g000), dot(g010, g010), dot(g100, g100), dot(g110, g110)));
    g000 *= norm0.x;
    g010 *= norm0.y;
    g100 *= norm0.z;
    g110 *= norm0.w;
    vec4 norm1 = taylorInvSqrt(vec4(dot(g001, g001), dot(g011, g011), dot(g101, g101), dot(g111, g111)));
    g001 *= norm1.x;
    g011 *= norm1.y;
    g101 *= norm1.z;
    g111 *= norm1.w;

    float n000 = dot(g000, Pf0);
    float n100 = dot(g100, vec3(Pf1.x, Pf0.yz));
    float n010 = dot(g010, vec3(Pf0.x, Pf1.y, Pf0.z));
    float n110 = dot(g110, vec3(Pf1.xy, Pf0.z));
    float n001 = dot(g001, vec3(Pf0.xy, Pf1.z));
    float n101 = dot(g101, vec3(Pf1.x, Pf0.y, Pf1.z));
    float n011 = dot(g011, vec3(Pf0.x, Pf1.yz));
    float n111 = dot(g111, Pf1);

    vec3 fade_xyz = fade(Pf0);
    vec4 n_z = mix(vec4(n000, n100, n010, n110), vec4(n001, n101, n011, n111), fade_xyz.z);
    vec2 n_yz = mix(n_z.xy, n_z.zw, fade_xyz.y);
    float n_xyz = mix(n_yz.x, n_yz.y, fade_xyz.x); 
    return 2.2 * n_xyz;
}



void main()
{
    
    // float strength = mod(vUv.x * 10.0, 1.0);
    // strength = step(0.9, strength);
    
    
    
    float strength = floor(vUv.x * 256.0) / 256.0;
    strength *= cnoise(vec3(vUv.x * 5.0,vUv.x * 5.0,uTime * 0.2));
    
    gl_FragColor = vec4(1.0, 1.0, 1.0, strength);

}
`

const firefliesVertex = `
uniform float uPixelRatio;
uniform float uTime;

float random (vec2 st) {
    return fract(sin(dot(st.xy,vec2(12.9898,78.233)))*43758.5453123);
}


void main()
{
    vec4 modelPosition = modelMatrix * vec4(position, 1.0);
    modelPosition.y += sin(uTime * .1 + modelPosition.x * .4);
    modelPosition.x += (random(vec2(2.5)) - 0.5) * sin(uTime * .1 + modelPosition.y * 0.4) * 2.0;
    vec4 viewPosition = viewMatrix * modelPosition;
    vec4 projectionPosition = projectionMatrix * viewPosition;

    gl_Position = projectionPosition;
    gl_PointSize = 100.0 * uPixelRatio;
    gl_PointSize *= (1.0 / - viewPosition.z);

}
`
const firefliesFragment = `
void main()
{
    float distanceToCenter = distance(gl_PointCoord, vec2(0.5));
    float strength = 0.05 / distanceToCenter - 0.1;

    gl_FragColor = vec4(1.0, 1.0, 1.0, strength);
}
`



const ShopExperience = ({path}) => {
    const sheet = getProject('ArchitectureExperience', {state : travelingState}).sheet('ArchitectureExperience')

    React.useEffect(() => {
        document.querySelector('body').style.background = "black"
        if(path === "/"){
            document.querySelector('body').style.height = "100vh"
        }
        return () => {
            document.querySelector('body').style.background = "#FFF9EF"
            document.querySelector('body').style.height = "100%"
        }
    }, [])

    return <>
        <SheetProvider sheet={sheet}>
            <Scene path={path} />
        </SheetProvider>

        {/* <OrbitControls /> */}
    </>
}

export default ShopExperience



const Scene = ({path}) => {
    const model = useGLTF(architecture)
    const bakedTextureArchi = useTexture(architectureBaked)
    bakedTextureArchi.flipY = false

    // const waterModel = useGLTF(water)
    const waterTexture = useTexture(waterBake)
    waterTexture.flipY = false

    const accessoriesModel = useGLTF(accessories)
    const accessoriesTexture = useTexture(accessoriesBake)
    accessoriesTexture.flipY = false
    
    const bodyModel = useGLTF(body)
    const bodyTexture = useTexture(bodyBake)
    bodyTexture.flipY = false

    const voileModel = useGLTF(voile)
    const voileTexture = useTexture(voileBake)
    voileTexture.flipY = false

    const bdyModel = useGLTF(bdy)
    const bdyTexture = useTexture(bdyBake)
    bdyTexture.flipY = false

    const busteModel = useGLTF(buste)
    const busteTexture = useTexture(busteBake)
    console.log(busteModel)
    busteTexture.flipY = false

    const maskModel = useGLTF(mask)
    const maskTexture = useTexture(maskBake)
    maskTexture.flipY = false

    const sheet = useCurrentSheet();
    const camera = React.useState()

    const waterShaderRef = React.useRef()
    const smokeShaderRef = React.useRef()
    const firefliesShaderRef = React.useRef()

    const [display, setDisplay] = React.useState(true)

    const {progress} = useProgress()


    React.useEffect(() => {
        sheet.sequence.position = 0
    }, [])
    
    useFrame((state, delta) => {

        const sequenceLength = val(sheet.sequence.pointer.length);
        
        // Push to the article page

        if(state.camera.position.z >= -11.3 ) {
            navigate("/" + "en" + "/products")
        }


        //Update scroll timeline
        if(path == "/"){
            console.log(state.camera.position)
            if( sheet.sequence.position + window.lenis.velocity/2000 >= 0 &&
            sheet.sequence.position + window.lenis.velocity/2000 < sequenceLength &&
            state.camera.position.z < -11.3 ){
                sheet.sequence.position += window.lenis.velocity/2000;
            } 
        }

        if(progress >= 100){
            eventBus.dispatch("sceneLoaded", {})
        }
        
        // Update shaders
        if(display){

            waterShaderRef.current.uniforms.uTime.value += delta
            firefliesShaderRef.current.uniforms.uTime.value += delta
        }

    })
    

    const firefliesCount = 50
    const scaleArray = new Float32Array(firefliesCount)
    const positionArray = new Float32Array(firefliesCount * 3)
    
    for(let i = 0; i < firefliesCount; i++) {
        positionArray[i * 3 + 0] = Math.random() * 30 - 15//* 15 - 10
        positionArray[i * 3 + 1] = Math.random() * 10 - 2.5
        positionArray[i * 3 + 2] = Math.random() * 30 - 11 - 15//* 15 - 10
        scaleArray[i] = Math.random()

    }
    

    return <>
        {/* <ambientLight /> */}
{
    display && <>
         <mesh 
        geometry={model.nodes.ArchitectureBis.geometry}
        >
            <meshBasicMaterial map={bakedTextureArchi} side={THREE.DoubleSide} />
        </mesh>



        <group
            position={[0.3, -6.9, -10.5]}
            rotation-y={3.6}
        >
              <mesh 
                geometry={accessoriesModel.nodes["Accessoires"].geometry}
                position={accessoriesModel.nodes["Accessoires"].position}
                rotation={accessoriesModel.nodes["Accessoires"].rotation}
            >
                <meshBasicMaterial map={accessoriesTexture} side={THREE.DoubleSide} />
            </mesh>
            <mesh 
                geometry={maskModel.nodes["Masque"].geometry}
                position={maskModel.nodes["Masque"].position}
                rotation={maskModel.nodes["Masque"].rotation}
            >
                <meshBasicMaterial map={maskTexture} side={THREE.DoubleSide} />
            </mesh>

            <mesh 
                geometry={bodyModel.nodes["Dress-Bas"].geometry}
                position={bodyModel.nodes["Dress-Bas"].position}
                rotation={bodyModel.nodes["Dress-Bas"].rotation}
            >
                <meshBasicMaterial map={bodyTexture} side={THREE.DoubleSide} />
            </mesh>
            <mesh 
                geometry={voileModel.nodes["Cube"].geometry}
                position={voileModel.nodes["Cube"].position}
                rotation={voileModel.nodes["Cube"].rotation}
                scale={voileModel.nodes["Cube"].scale}
            >
                <meshBasicMaterial map={voileTexture} side={THREE.DoubleSide} />
</mesh>
            <mesh 
                geometry={bdyModel.nodes["Santa002"].geometry}
                position={bdyModel.nodes["Santa002"].position}
                rotation={bdyModel.nodes["Santa002"].rotation}
            >
                <meshBasicMaterial map={bdyTexture} side={THREE.DoubleSide} />
            </mesh>
            <mesh
                geometry={busteModel.nodes["Bustebis"].geometry}
                position={busteModel.nodes["Bustebis"].position}
                rotation={busteModel.nodes["Bustebis"].rotation}
            >
                <meshBasicMaterial map={busteTexture} side={THREE.DoubleSide} />
            </mesh>
        </group>

        <mesh 
            position={[-0.6, 4, -11.2]}
            rotation-z={0.2}>
            <coneGeometry args={[1, 16, 256]} />
            <shaderMaterial
                transparent={true}
                // side={THREE.DoubleSide}
                ref={smokeShaderRef}
                wireframe={false}
                vertexShader={coneVertexShader}
                fragmentShader={coneFragmentShader}
                uniforms={{
                    uTime : {value : 12},
                }}
            />
            </mesh>

        <mesh 
            rotation-x={Math.PI / 2} 
            position-y={-3.7}
            position-x={0}
            position-z={-10.5}
            rotation-z={Math.PI - 0.44}
            scale={1.2}
            >
            <planeGeometry args={[20, 20, 100, 100]}/>
            <shaderMaterial
                side={THREE.DoubleSide}
                ref={waterShaderRef}
                wireframe={false}
                vertexShader={vertexShader}
                fragmentShader={fragmentShader}
                uniforms={{
                    uTime : {value : 0},
                    uBigWavesFrequency: { value: {x : .05, y : .06} },
                    uBigWavesElevation: { value: .05 },
                    uTexture : {value: waterTexture },
                    uColorOffset: { value: 0.375 },
                    uColorMultiplier: { value: 0.200 },
                }}
            /> 

        </mesh>

        <points>
      <bufferGeometry>
        <bufferAttribute
          attach='attributes-position'
          count={firefliesCount}
          itemSize={3}
          array={positionArray}
        />
      </bufferGeometry>
      <shaderMaterial
                transparent={true}
                side={THREE.DoubleSide}
                ref={firefliesShaderRef}
                wireframe={false}
                vertexShader={firefliesVertex}
                fragmentShader={firefliesFragment}
                // side={THREE.DoubleSide}
                uniforms={{
                    uTime : {value : 12},
                    uPixelRatio: { value: Math.min(window.devicePixelRatio, 2) },
                    uTime: { value: 0 }
                }}
            />
            </points>
    {path === "/" &&<PerspectiveCamera
            theatreKey="Camera"
            makeDefault
            position={[0, 0, 0]}
            fov={90}
            near={0.1}
            ref={camera}
            far={70}
        />  
            }
    </>
}
 
    </>


}


