/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
const React = require("react");
const Layout = require("./src/components/layouts/PageLayout.jsx").default;
const eventBus = require("./src/assets/scripts/utils/eventBus.js").default;

exports.wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};

exports.onRouteUpdate = ({ location, prevLocation }) => {
  eventBus.dispatch("routeChange", location);
};

exports.shouldUpdateScroll = () => {
  return false;
};
