import React from "react"

import { useSelector, useDispatch } from "react-redux";
import { setConnected, setData, set, setLang } from "../../assets/scripts/store/redux-slices/authentication";
import { setCart } from "../../assets/scripts/store/redux-slices/cart";
import eventBus from "../../assets/scripts/utils/eventBus"

const HandlePersistantData = () => {
    const dispatch = useDispatch()

    const retrieveSession = () => {
        let user = localStorage.getItem("user");


        if(!user){
            
            user = { }

          }else{
            try{
              
                user = JSON.parse(user);

            }catch{
              
                user = { }

            }  
          }
          
          if(user._id){
      
            dispatch(setData(user))


            return user
            
          }

          return false
    }

    const retrieveCart = () => { 

        let cart = localStorage.getItem("santa_muerte_cart");
        if(!cart){
            cart = {}

        } else {


            try{
              
                cart = JSON.parse(cart);
                
            }catch{
              
                cart = { }

            }  


        }

        if(cart.total){
            dispatch(setCart(cart))
            return cart
        }

        return false
    }   

    React.useEffect(() => {
        
        const session = retrieveSession()
        const cart = retrieveCart()

        if(session){
            dispatch(setConnected())
        }

        const lang = window.location.pathname.split("/").filter(w => w !== "")[0]
        if(lang && (lang === "fr" || lang === "en" || lang === "es" || lang === "it") ){
            dispatch(setLang(lang.toUpperCase()))
        } 

        eventBus.dispatch("dataRetrieved", {
            session : session,
            cart : cart
        })
    }, [])


    return <></>

}

export default HandlePersistantData