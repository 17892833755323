import React from "react"
import "./style.scss"
import { Link } from "gatsby"
import {useSelector} from "react-redux"

const Button = (props) => {
    const {type, children, isSelected, callBack, label, to, value, target="", max=1, customClass, isUnderline=true} = props
    const lang = useSelector((state) => state.authentication.lang);
    const labels = {
        more : {
            FR : "Plus",
            EN : "More",
            ES : "mas"
        }, 
        less : {
            FR : "Moins",
            EN : "Less",
            ES : "menos"
        },
        soundOff : {
            FR : "activater le son",
            EN : "activate sound",
            ES : "activar el sonido"
        },
        sound : {
            FR : "déactivater le son",
            EN : "deactivate sound",
            ES : "desactivar el sonido"
        }
    }
    const qtyButton = React.useRef()
    const [isLocked, setIsLocked] = React.useState(false)

    const increase = () => {
        if(!isLocked && value < max){
            setIsLocked(true)
            qtyButton.current.querySelectorAll('.EMI-BUTTON_quantity_button span').forEach((span) => {

                span.style.transition = `1s cubic-bezier(0.83, 0, 0.15, 1)`
                span.style.transform = `translateY(-200%)`

                    
            })
            setTimeout(() => {

                qtyButton.current.querySelectorAll('.EMI-BUTTON_quantity_button span').forEach((span) => {
                    span.style.transition = `0s`
                    span.style.transform = `translateY(-100%)`
                })
                callBack("more")
                setIsLocked(false)
            }, 1000)
        }
    }

    const decrease = ( ) => {
        if(!isLocked && value > 1){
            setIsLocked(true)
            
            qtyButton.current.querySelectorAll('.EMI-BUTTON_quantity_button span').forEach((span) => {
                span.style.transition = `1s cubic-bezier(0.83, 0, 0.15, 1)`
                span.style.transform = `translateY(0%)`
                setTimeout(() => {
                    span.style.transition = `0s`
                    span.style.transform = `translateY(-100%)`
                }, 1010)
            })
            setTimeout(() => {
                setIsLocked(false)
                callBack("less");
            }, 1000)
        }
    }
    switch (type) {
        case "text":
            return <button onClick={() => {callBack()}} className={`EMI-BUTTON_remove_button EMI-button-big ${customClass}`}>
                {children}
            </button>

        case "categorie":
            return <button onClick={() => {callBack()}} className={`EMI-BUTTON_categorie_button EMI-categorie ${isSelected ? "selected" : ""}`}>
                {children}
            </button>

        case "size":
            return <button onClick={() => {callBack()}} className={`EMI-BUTTON_size_button EMI-paragraph-regular ${isSelected ? "selected" : ""}`}>
                {children}
            </button>

        case "icon":
            if(to){
                return <Link to={to} target={target} aria-label={label} className={`EMI-BUTTON_icon_button`}>
                    {children}
                </Link>
            }
            return <button onClick={() => {callBack()}} aria-label={label} className={`EMI-BUTTON_icon_button`}>
                {children}
            </button>
  
        case "quantity":

            return <div className="EMI-BUTTON_quantity_button" ref={qtyButton}>
            <button onClick={() => {decrease("less")}} className={value <= 1 ? "not_in_stock" : ""} aria-label={labels.less[lang.toUpperCase()]}>
                <svg width="18" height="2" viewBox="0 0 18 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="-4.5897e-08" y1="1.28003" x2="17.1865" y2="1.28003" stroke="black"/>
                </svg>
            </button>
            <div>
                <span>{value - 1}</span>   
                <span>{value}</span>   
                <span>{value + 1}</span>   
            </div>
            <button onClick={() => {increase("more")}} className={value >= max ? "not_in_stock" : ""} aria-label={labels.more[lang.toUpperCase()]}>
                <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="8.81641" y1="16.9641" x2="8.81641" y2="0.595991" stroke="black"/>
                    <line x1="0.186523" y1="8.79163" x2="17.3731" y2="8.79162" stroke="black"/>
                </svg>
            </button>
        </div>

        case 'link': 
            return <Link to={to} target={target} className={`EMI-BUTTON_link_button ${customClass}`}>
            {children}
            </Link>

        case 'underline': 
            if(to){
                return <Link to={to} target={target} className={"EMI-BUTTON_link_text_button EMI-paragraph-regular " + " " + `${isUnderline ? "selected" : "not-selected"}`}>
            {children}
            </Link>
            }
            return <button onClick={()=>callBack()} className="EMI-BUTTON_link_text_button EMI-paragraph-regular">
            {children}
            </button>

        case 'lang': 
            return <button onClick={() => {callBack()}} className="EMI-BUTTON_lang_button">
                {children}
            </button>
        
        case 'sound': 
            return <button onClick={() => {callBack()}} className="EMI-BUTTON_sound_button" aria-label={labels.sound[lang.toUpperCase()]}>
                <svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect y="8" width="5.33333" height="16" rx="2.66667" fill="#635F5D"/>
                    <rect x="18.8242" y="8" width="5.33333" height="16" rx="2.66667" fill="#635F5D"/>
                    <rect x="9.41205" width="5.33333" height="32" rx="2.66667" fill="#635F5D"/>
                    <rect x="28.2362" width="5.33333" height="32" rx="2.66667" fill="#635F5D"/>
                </svg>
            </button>


        case "sound-off": 
            return <button onClick={() => {callBack()}} className="EMI-BUTTON_sound_button" aria-label={labels.soundOff[lang.toUpperCase()]}>
<svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect y="20.032" width="5.33333" height="5.33333" rx="2.66667" fill="#635F5D"/>
<rect x="17.4645" y="20.032" width="5.33333" height="5.33333" rx="2.66667" fill="#635F5D"/>
<rect x="8.73232" y="17.3654" width="5.33333" height="8" rx="2.66667" fill="#635F5D"/>
<rect x="26.1969" y="17.3654" width="5.33333" height="8" rx="2.66667" fill="#635F5D"/>
</svg>

            </button>
        case 'select': 
            

        default:
            if(to){
                return <Link to={to} className={`EMI-BUTTON_default_button EMI-button ${customClass}`}>
                    <p>{children}</p>
                    <div><p>{children}</p></div>
                </Link>
            }
            return <button onClick={() => {callBack()}} className={`EMI-BUTTON_default_button EMI-button ${customClass}`}>
            <p>{children}</p>
            <div><p>{children}</p></div>
        </button>
    }
}

export default Button